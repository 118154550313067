@import '../../variables.scss';

$background: $backgroundDark;
$color: white;

.mathTest {
  box-sizing: border-box;
  background-color: $background;
  color: $color;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  padding: $defaultPadding;


  .questionContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    flex: 1 0;
  }
  
  .questionContainer > div {
    color: $color;
    border-radius: $borderRadius;
    margin: 3px;
    flex: 1 1 360px;
    border: 1px dotted $grayDark;
    padding: 9px 5px;
    @media screen and (max-width:$viewPhone) {
      border: none;
      border-bottom:1px dotted $grayDark;
      border-radius: 0;
      padding: 20px 0;
      flex: 1 1 100%
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    flex: 1 0 auto;
    padding: $defaultPadding 0;
  }
}