@import '../../variables.scss';

.testSectionNavigator {
  display: flex;
  flex-direction: column;
  color: $color;
 
  align-items: center;
  
  .titleBar {
    background: white;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .testTitle {
      max-width: 400px;
    }
    
  }
  .testSections {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    align-content: center;
    justify-content: center;
  }

}
