@import '../../../variables.scss';

.percentileSummary {
  display: flex;
  flex-direction: column;

  .composite {
    display: flex;
    flex-direction: column;
  }
  .sections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}