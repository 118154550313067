@import '../../variables.scss';

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: $titleLarge;
	background-color: $backgroundDark;
	background-image: $backgroundImage;
  color: white;
}

.testResults {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 100%;
	background-color: $backgroundDark;
	background-image: $backgroundImage;
  color: white;

  font-size: $subHeader;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  .mainContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    align-items: center;
  }
  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 2px solid $gray;
    box-shadow: 0 -5px 30px $grayExtraDark;
    margin-bottom: 35px;
  }
  .footer {
    padding-top: 25px;
    a {
      color: $primaryLight;
    }
  }
  .resultsHeader {
    font-weight: bold;
    font-size: $appTitle;
    padding-bottom: 40px;
  }
  .subHeader {
    text-align: center;
    font-size: $subHeader;
    width: 100%;
    padding-bottom: 25px;
    span {
      color: $testiveOrange;
      font-weight: $extraBold;
    }
  }
  .infoHeader {
    margin-bottom: $defaultPadding;
    display: flex;
    font-size: $standard;
    flex-direction: column;
    span {
      font-weight: $semibold;
    }
  }
  .infoItem{
    padding: 3px 0;
  }
  .breakdown{
    padding: 10px 0;
  }
  .data {
    display: flex;
    flex-direction: column;
  }
  .dataEntry {
    padding: 5px 0 15px 0;
  }
  .dataHeader {
    font-weight: bold;
    font-size: $header;
    padding-bottom: 10px;
  }
  .dataText {
    padding-bottom: 15px;
  }
  .score {
    font-weight: bold;
    text-align: center;
  }
  .time {
    font-size: $standard;
    width: 50%;
    .timeItem {
      padding: 5px;
    }
  }
  .breakdown {
    display: flex;
    flex-wrap: wrap;
  }
  .percentCorrect {
    font-size: $standard;
    width: 50%;
  }
}