@import './../../variables.scss';

.login {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	color: white;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: $backgroundDark;
	background-image: $backgroundImage;

	.mainContainer {
		box-sizing: border-box;
		padding: 5vh 5vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		border: 1px solid white;
		border-radius: $borderRadius;
		@media screen and (max-width:$viewPhone) {
			flex: 1;
			width: 100%;
			border: none;
		}
	}
	.titleContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		.name {
			font-size: $title;
			font-weight: bold;
		}
	}
	img {
		max-width: 300px;
		margin-top: 30px;
	}
	.bottom {
		width: 100%;
	}
	.formContainer {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		margin-top: 5vh;
	}
	.buttonContainer {
		display: flex;
		flex-direction: row;
		margin: 1vh 0;
		width: 100%;	
	}
	input, select {
		flex: 1;
		padding: 12px 20px;
		box-sizing: border-box;
		font-size: $standard;
		border: 1px solid $gray;
		background-color: white;
		color: black;
		margin-bottom: 1vh;
		border-color: $grayDark;
		border-radius: $borderRadius;
		width: 100%;
		outline: none;
	}
	input[type=submit] {
		-webkit-appearance: none;
		border: none;
		font-size: $standard;
		border: 1px solid $buttonColorB2;
		background-color: $buttonColorB1;
		color: $buttonColorB2;
		-webkit-border-radius: $borderRadius;
		border-radius: $borderRadius;
		box-sizing:content-box;
		font-size: $standard;
		font-weight: $semibold;
		outline: none;
		padding: 12px 20px;
		text-transform: uppercase;
		margin: 2px;
		width: 100%;
	}
	input[type=submit]:hover  {
		cursor: pointer;
		border-width: 3px;
		margin: 0;
		font-weight: $bold;
	}
}