@import "../../variables.scss";

$lineNumberColumn: 2.5vmin;
.passage {
  display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	padding-top: 1vh;
	padding-left: 2vmin;
	background-color: white;
	.passageBody {
		font-size: $standard;
		display: flex;
		flex-direction: column;
		flex: 1;
		align-items: center;
		p {
			margin-block-end: 0;
			margin-block-start: 0;
		}
		div {
			display: inline-block;
			margin-left: -2.5vmin;
			font-size: 1.5vmin;
			width: 2.5vmin;
		}
		.para {
			margin-bottom: 2vh;
		}
		.figure {
			display: flex;
			flex-direction: column;
			flex: 1;
		}

	}
	.table {
		margin-bottom: 2vh;
		.tableTitle {
			font-size: $standard;
			font-weight: $bold;
		}
		table {
			margin-top: 1vh;
			border-collapse: collapse;
		}
		table, th, td {
			border: 1px solid black;
			text-align: center;
			padding: 3px 8px;
		}
	}
	.passageFigure {
		margin-bottom: 2vh;
	}
	.lineNum {
		font-family: 'Courier New', Courier, monospace;
		font-weight: $semibold;
		margin-left: -$lineNumberColumn;
		font-size: 1.5vmin;
		width: $lineNumberColumn;
	}
}
