@import './../../variables.scss';

.errorMessage {
    min-height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: white;
    border: 1px solid $errorColor;
    color: $errorColor;
    border-radius: $borderRadius;
}
.errorTitle {
    color: $errorColor;
    font-weight: bold; 
}
.errorList {
    display: flex;
    flex-direction: column;
}
.errorText {
    color: $errorColor;
}
.errorIcon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}
