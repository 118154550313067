@import '../../../variables.scss';

.percentile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  .chartContainer {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-width: 50px;
    min-height: 50px;
  }
  .score {
    display: flex;
    align-items: center;
    position: absolute;
    font-size: calc(24px + 1vmin);
    font-weight: $light;
    .percent { 
      font-size: $tinyText;
    }
  }
  .chartTitle {
    box-sizing: border-box;
    display: flex;
    font-size: $subHeader;
  }
}

:export { completed: $primary; }
