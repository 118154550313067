@import "../../variables.scss";

.passageTest {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  max-height: calc(100% - 56px);
  border-bottom: 1px solid $backgroundDark;
}

.passage {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  flex: 1 1 auto;
}

.question {
  background: $backgroundDark;
  border-left: 1px solid $grayDark;
  padding: 5px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  flex: 0 0 40vw;
  min-width: 300px;
}