// Colors
$testiveOrange: #f58b1e;
$testiveOrangeLight: #fac58e;
$primary: #00a2a7;
$primaryLight: #00d3da;
$primaryExtraLight: #01F0FB;
$primaryDark: #007C82;
$primaryExtraDark: #006165;
$secondary: #134f8d;
$secondaryLight: #5ba0d0;
$secondaryDark: #2c3e50;
$secondaryExtraLight: #ceddef;
$gray: #cecece;
$grayDark: #959ea7;
$grayExtraDark: #1d1f21;
$grayLight: #e9ebed;
$grayExtraLight: #f6f6f6;
$errorColor: #ff0033;
$highlightColor:#01F0FB;

$math:#963484;
$ela: #134f8d;
$science: #3B3561;
$scienceSmallText: #d1c6fc;
$scienceText: #a99fd3;
$reading: #F5AB00;
 
$color: white;
$headerColor: lighten($grayExtraDark, 10%);
$backgroundLight: white;
$backgroundDark: $headerColor;
$backgroundImage: linear-gradient(lighten($grayExtraDark, 20%), $grayExtraDark);

$defaultPadding: 2vmin;

$buttonColorA1: $testiveOrange;
$buttonColorA2: white;
$buttonColorB1: $primary;
$buttonColorB2: white;
$buttonColorA1Highlight: #F9F871;

$timerDefault: #00d3da;
$timerFirstWarning: #F9F871;
$timerSecondWarning: #f58b1e;
$timerFinalWarning: #ff0033; 
// #F3615E 
$maxContentWidth: 800px;

// Fonts
$primaryFont: 'proxima-nova';
$headerFont: 'Roboto Slab';
$fontColor: white;

$headerHeight: 56px;

// Font Sizes
$tinyText: calc(4px + 1vmin);
$extraSmall: 0.25rem;
$small: calc(5px + 1vmin);
$standard: calc(8px + 1vmin);
$smallHeader: calc(10px + 1vmin); // 1.125rem;
$subHeader: calc(14px + 1vmin); // 1.125rem;
$header: 1.5rem;
$title: 2rem;
$titleLarge: 32px;
$appTitle: calc(36px + 1vmin);

// Font Weights
$thin: 100;
$light: 300;
$regular: 400;
$regularItalic: 400;
$semibold: 600;
$bold: 700;
$extraBold: 800;

// miscellaneous
$borderRadius: 5px;
$maxWidth: 800px;

// breakpoints
$viewPhone: 600px;
$viewTablet: 900px;
$viewDesktop: 1200px;

$breakWidth: 900px;