@import '../../variables.scss';

.testSection {
  box-sizing: border-box; 
  // border-radius: $borderRadius;
  border: 5px solid rgba(255,255,255,0.25);
  margin: 0px 5px 5px 5px;
  min-width: 300px;
  .innerBoarder {
    
    box-sizing: border-box;
    // border: 1px solid white;
    // border-radius: $borderRadius;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 10px;
  }

  .sectionInfo {
    // border: 1px solid white;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5px 0 0 5px;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    // border: 1px solid;
    .logo {
      margin-right: 10px;
      max-width: 50px;
      img {
        width: 100%;
      }
    }
    .sectionName {
      font-size: $header;
    }
  }
  
  .sectionDesc {
    box-sizing: border-box;
    background:rgba(255,255,255,0.25);
    // border: 1px solid white;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
    // margin-bottom: 30px;
    padding: 20px 10px 10px 20px;
    font-size: $smallHeader;

    .sectionDescItem {
      padding: 2px 0;
      // list-style-type: circle;
    }
  }

  .sectionProgress {
    // border: 1px solid white;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 175px;
    height: 175px;
  
    .chartSpacer {
      height: 40px;
    }
    Button {
      margin: 0 auto;
    }
  }
  
}

