@import '../../variables.scss';

.chooseTest {
  display: flex;
  flex-direction: column;
  background: white;
  align-items: center;
  height: 100%;
}

.header {
  box-sizing: border-box;
  color: black;
  padding: 10vh 0 5vh;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  font-size: $title;
  max-width: 600px;
}

.testContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  align-items: center;
  justify-content: center;
}

.test {
  font-size: $header;
  text-align: center;
  color: white;
  padding: 20px 60px;
  margin: 15px;
  border-radius: $borderRadius;
  cursor: pointer;
}

.act {
  background-color: #F14539;
  &:hover {
    background-color: lighten(#F14539, 10%);
  }
}

.sat {
  background-color: #6072FA;
  &:hover {
    background-color: lighten(#6072FA, 10%);
  }
}