@import "../../variables.scss";

.question {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  font-size: $standard;
  color: $fontColor;
  padding: 1vmin;
  .questionBody {
    display: flex;
    flex-direction: row;
    padding: 1vmin;
    margin-bottom: 1vmin;
    .questionNumber {
      padding-right: 1vmin;
      font-weight: $semibold;
    }

    img {
      margin: 2vh 0;
      max-height: 80vw;
      max-width: 80vw;
    }
    .table {
      margin: 2vh 0;
      .tableTitle {
        font-size: $standard;
        font-weight: $bold;
      }
      table {
        margin-top: 1vh;
        border-collapse: collapse;
      }
      table, th, td {
        border: 1px solid;
        text-align: center;
        padding: 3px 8px;
      }
    }
  }
  .answerSet {
    display: flex;
    flex-direction: column;
  }
  .answerButton {
    padding-top: 20px;
  }
}
